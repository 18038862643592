<template>
  <div>
    <text-input
      id="title"
      name="Title"
      rules="required"
      :text.sync="role.displayName"
    />

    <text-input
      id="description"
      name="Description"
      rules="required"
      :text.sync="role.description"
    />

  </div>
</template>
<script>
import TextInput from '../common/FormInputs/TextInput.vue'

export default {
  name: 'RoleForm',
  components: { TextInput },
  props: {
    role: { type: Object, default: () => {} },
  },
}
</script>
<style lang="scss">

</style>
